<template>
  <div>
    <nav class="navTab">
      <div :class="tabIndex==0?'isDivcom':''" @click="tabNavtilte(0)">
        <h2>岗位详情</h2>
      </div>
      <div :class="tabIndex==1?'isDivcom':''" @click="tabNavtilte(1)">
        <h2>求职人（{{connt?connt:'0'}}）</h2>
      </div>
    </nav>
    <!-- 这里用v-show, 用v-if的话切回来的时候地图没了 -->
    <main class="ent_main m_top10" v-show="tabIndex==0">
      <div class="tilte_btn">
        <span class="lineY"></span>
        <span>
          <h2>职位基础信息</h2>
        </span>
        <el-button @click="editorJob" type="primary" class="el_btn" size="medium">编辑职位信息</el-button>
        <el-button @click="batchAuditDialogVisible=true;ids=$route.query.id" type="warning" class="el_btn" size="medium">职位审核</el-button>
      </div>
      <div style="padding:10px;">
        <span class="ent_del">职位名称：</span><span class="ent_detail">{{JobDetail.jobinfo.jobname?JobDetail.jobinfo.jobname:'无'}}</span>
      </div>
      <div style="padding:10px">
        <el-row>
          <el-col :span='10'>
            <span class="ent_del">行业类别：</span>
            <span class="ent_detail">{{JobDetail.jobinfo.indtypeNames?JobDetail.jobinfo.indtypeNames:'无'}}</span>
          </el-col>
          <el-col :span='10'>
            <span class="ent_del ent_delFr">职位类别：</span>
            <span class="ent_detail">{{JobDetail.jobinfo.jobtype==1?'全职':'普工/兼职'}}</span>
          </el-col>
        </el-row>
      </div>
      <div style="padding:10px">
        <el-row>
          <el-col :span='10'>
            <span class="ent_del">招聘人数：</span>
            <span class="ent_detail" v-if="JobDetail.jobinfo.recruitNumMin != -1">
              {{JobDetail.jobinfo.recruitNumMax}} 人
            </span>
            <span class="ent_detail" v-else-if="JobDetail.jobinfo.recruitNumMax != -1">
              {{JobDetail.jobinfo.recruitNumMin}} 人
            </span>
            <!-- <span class="ent_detail" v-if="JobDetail.jobinfo.recruitNumMin == -1">
              {{JobDetail.jobinfo.recruitNumMax}}以下
            </span>
            <span class="ent_detail" v-else-if="JobDetail.jobinfo.recruitNumMax == -1">
              {{JobDetail.jobinfo.recruitNumMin}}以上
            </span>
            <span class="ent_detail" v-else-if="JobDetail.jobinfo.recruitNumMin != -1 && JobDetail.jobinfo.recruitNumMax != -1">
              {{JobDetail.jobinfo.recruitNumMin}}~{{JobDetail.jobinfo.recruitNumMax}}人
            </span> -->
          </el-col>
          <el-col :span='10'>
            <span class="ent_del ent_delFr">薪资待遇：</span>
            <span class="ent_detail" v-if="JobDetail.jobinfo.minSalary == -1 && JobDetail.jobinfo.maxSalary == -1">
              面议
            </span>
            <span class="ent_detail" v-else-if="JobDetail.jobinfo.minSalary != -1 && JobDetail.jobinfo.maxSalary != -1">
              {{JobDetail.jobinfo.minSalary}}~{{JobDetail.jobinfo.maxSalary}}元
            </span>
            <span class="ent_detail" v-else-if="JobDetail.jobinfo.minSalary == -1">
              {{JobDetail.jobinfo.maxSalary}}元以下
            </span>
            <span class="ent_detail" v-else-if="JobDetail.jobinfo.maxSalary == -1">
              {{JobDetail.jobinfo.minSalary}}元以上
            </span>
            <span class="ent_detail" v-else-if="JobDetail.jobinfo.minSalary == 0 && JobDetail.jobinfo.maxSalary != -1">
              {{JobDetail.jobinfo.maxSalary}}元
            </span>
          </el-col>
        </el-row>
      </div>
      <div style="padding:10px">
        <el-row>
          <el-col :span='10'>
            <span class="ent_del">学历要求：</span>
            <span class="ent_detail">{{JobDetail.jobinfo.educationShow?JobDetail.jobinfo.educationShow:'无'}}</span>
          </el-col>
          <el-col :span='10'>
            <span class="ent_del ent_delFr">经验要求：</span>
            <span class="ent_detail" v-if="JobDetail.jobinfo.workExperienceMin == -1 && JobDetail.jobinfo.workExperienceMax == -1">
              不限
            </span>
            <span class="ent_detail" v-else-if="JobDetail.jobinfo.workExperienceMin != -1 && JobDetail.jobinfo.workExperienceMax != -1">
              {{JobDetail.jobinfo.workExperienceMin}}~{{JobDetail.jobinfo.workExperienceMax}}年
            </span>
            <span class="ent_detail" v-else-if="JobDetail.jobinfo.workExperienceMin == -1">
              {{JobDetail.jobinfo.workExperienceMax}}年以下
            </span>
            <span class="ent_detail" v-else-if="JobDetail.jobinfo.workExperienceMax == -1">
              {{JobDetail.jobinfo.workExperienceMin}}年以上
            </span>
          </el-col>
        </el-row>
      </div>
      <div style="padding:10px">
        <span class="ent_del">职位亮点：</span>
        <el-button type="danger" size="medium" v-for="(item,index) in JobDetail.jobinfo.jobLightPointArr" :key="index" v-if="item">
          {{item}}
        </el-button>
        <span class="ent_detail" v-if="!(JobDetail.jobinfo.jobLightPointArr)||JobDetail.jobinfo.jobLightPointArr.length<=0">无</span>
      </div>
      <div style="padding:10px">
        <span class="ent_del">工作地址：</span>
        <span class="ent_detail">
          {{JobDetail.jobinfo.wprovinceName?JobDetail.jobinfo.wprovinceName:'无地址'}}
          {{JobDetail.jobinfo.wcityName?JobDetail.jobinfo.wcityName:''}}
          {{JobDetail.jobinfo.wcountyName?JobDetail.jobinfo.wcountyName:''}}
          {{JobDetail.jobinfo.waddrDetail?JobDetail.jobinfo.waddrDetail:''}}
        </span>
      </div>
      <!-- <div class="mapBox" id="mapBox"> 
                     地图 
                </div> -->
      <div class="tilte_btn">
        <span class="lineY"></span>
        <span>
          <h2>联系方式</h2>
        </span>
      </div>
      <div style="padding:10px">
        <el-row>
          <el-col :span='8'>
            <span class="ent_del">联系人：</span>
            <span class="ent_detail">{{JobDetail.enterprise.contactsPerson?JobDetail.jobinfo.contactsPerson:''}}</span>
          </el-col>
          <el-col :span='8'>
            <span class="ent_del ent_delFr">联系方式：</span>
            <span class="ent_detail">{{JobDetail.enterprise.contactsNumber?JobDetail.jobinfo.contactsNumber:''}}</span>
          </el-col>
          <el-col :span='8'>
            <span class="ent_del ent_delFr">邮箱：</span>
            <span class="ent_detail">{{JobDetail.enterprise.contactsEmail?JobDetail.jobinfo.contactsEmail:''}}</span>
          </el-col>
        </el-row>
      </div>
      <div class="tilte_btn">
        <span class="lineY"></span>
        <span>
          <h2>职位职责</h2>
        </span>
      </div>
      <div style="padding:10px">
        <span class="ent_detail">{{JobDetail.jobinfo.jobduty?JobDetail.jobinfo.jobduty:'没有相关职责'}}</span>
      </div>
      <div class="tilte_btn">
        <span class="lineY"></span>
        <span>
          <h2>任职要求</h2>
        </span>
      </div>
      <div style="padding:10px">
        <span class="ent_detail">{{JobDetail.jobinfo.takeRequire?JobDetail.jobinfo.takeRequire:'没有相关要求'}}</span>
      </div>
    </main>
    <main class="ent_main m_top10" v-show="tabIndex==1">
      <div class="tilte_btn">
        <span class="lineY"></span>
        <span>
          <h2>求职人列表</h2>
        </span>
      </div>
      <div style="width:100%;height: 100px;line-height:100px;text-align:center;font-size: 20px" v-if="!memjoblist || memjoblist.length <= 0">没有数据喽</div>
      <div style="padding:0 10px">
        <div class="job_numder">
          <div class="job_num_box m_top10" v-for="(item,index) in memjoblist" :key="index">
            <div class="job_num_list">
              <div class="job_num_ter">
                <img :src="item.portraiturl" v-if="item.portraiturl">
                <img src="../../assets/img/noheadimg.png" alt="" v-else>
                <h4>{{item.username}}</h4>
                <p style="color:#999;margin:10px 0">投递时间：{{item.createtime?item.createtime.substring(0, 10):'暂无时间'}}</p>
                <!-- <p><span style="background:#f6f7f6;padding:4px">专业技能没有字段</span></p> -->
                <p><span style="background:#f6f7f6;padding:4px">号码：{{item.memphone?item.memphone:'此人没有填写号码'}}</span></p>
              </div>
              <div class="job_main_flex">
                <div class="job_list_m">
                  <p>年龄</p>
                  <p>{{item.age?item.age+'岁':'无年龄'}}</p>
                </div>
                <div class="job_list_m">
                  <p>工作经验</p>
                  <p>{{item.memworkExperience?item.memworkExperience+'经验':'无经验'}}</p>
                </div>
                <div class="job_list_m">
                  <p>最近被看</p>
                  <p>{{item.education?item.education:'0'}}次</p>
                </div>
              </div>
              <div style="width:90%;margin:auto; border-bottom:1px dotted #999;"></div>
              <div class="job_main_flex">
                <div class="job_list_m">
                  <p>寻求职业</p>
                  <p>{{item.ittcartypeNames?item.ittcartypeNames:'无职业'}}</p>
                </div>
                <div class="job_list_m">
                  <p>期望薪资</p>
                  <p v-if="item.ittminSalary==-1&&item.ittmaxSalary==-1">面议</p>
                  <p v-else-if="item.ittminSalary==-1">{{item.ittmaxSalary}}以下</p>
                  <p v-else-if="item.ittmaxSalary==-1">{{item.ittminSalary}}以上</p>
                  <p v-else>{{item.ittminSalary}}-{{item.ittmaxSalary}}元</p>
                </div>
                <div class="job_list_m">
                  <p>工作地区</p>
                  <p>
                    {{item.jobwprovinceName?item.jobwprovinceName:'无地址'}}
                    {{item.jobwcityName?'-'+item.jobwcityName:''}}
                  </p>
                </div>
              </div>
              <div class="job_num_ter" style="margin-top: -10px;">
                <el-button @click="getResDetail(item)" style="width:90%" type="danger">查看详情</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m_top30" style="height:60px;text-align: center;">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10,20, 30, 40, 50]" :page-size="10"
          layout="total, sizes, prev, pager, next, jumper" :total="connt">
        </el-pagination>
      </div>
    </main>
    <el-dialog title="职位批量审核" :visible.sync="batchAuditDialogVisible" width="50%">
      <template>
        <div class="audit-content">
          <div class="main-audit">
            <div class="audit-label">审核操作：</div>
            <div class="audit-c">
              <el-radio-group v-model="batchAuditData.audit">
                <el-radio :label="2">审核通过</el-radio>
                <el-radio :label="1">审核不通过</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="main-audit">
            <div class="audit-label">审核意见：</div>
            <div class="audit-c">
              <el-input type="textarea" :rows="4" placeholder="请输入审核意见" v-model="batchAuditData.auditmsg">
              </el-input>

            </div>
          </div>
        </div>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchAuditDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="batchAuditDialogOkClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { lazyAMapApiLoaderInstance } from 'vue-amap';
export default {
  name: "JobDetail",
  data () {
    return {
      jobid: '',
      tabIndex: 0,
      batchAuditDialogVisible: false,

      JobDetail: {
        jobinfo: {},
        enterprise: {}
      },
      pageNum: 1,
      pageSize: 10,
      connt: null,
      memjoblist: [],
      batchAuditData: {
        ids: "",
        audit: 0,
        auditmsg: ""
      },
      auditStatus: ["待审核", "<b style='color: red'>审核不通过</b>", "<b style='color: darkgreen'>审核通过</b>", "再次提交"],
    }
  },
  props: {
    comParam: {}
  },
  created () {
    var _self = this;
    //_self.comParam.id = this.$router.currentRoute.query.id;
    _self.jobid = this.$router.currentRoute.query.id;

    _self.findData();
    _self.getMemjobList(_self.jobid);
  },
  updated () {
    // console.log(this.$router.currentRoute.query);
  },
  methods: {
    //批量审核处理
    batchAuditDialogOkClick: function () {
      var _self = this;
      var msg = _self.auditStatus[_self.batchAuditData.audit];
      _self.$confirm('确定要将选择的职位' + msg + '吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '保存中，请稍后',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        });
        _self.batchAuditData.ids = _self.$route.query.id
        axios({
          url: _self.API.ajob.batchAudit,
          method: "POST",
          headers: {
            token: _self.Util.getjwtToken(),
          },
          data: _self.batchAuditData
        }).then(function (e) {
          loading.close();
          var res = e.data;
          if (res.success) {
            _self.$message.success(res.msg);
            _self.batchAuditDialogVisible = false;
            _self.getTableData(); //重新获取列表
          } else {
            _self.$message.error(res.msg);
          }
        }).catch(function (error) {
          loading.close();
        })
      })
    },
    tabNavtilte: function (index) {
      this.tabIndex = index
    },
    //获取求职人
    getMemjobList: function (jobId) {
      var _self = this;
      axios({
        url: _self.API.enterprise.getEnterpriseApplyJobPeople,
        method: 'post',
        headers: {
          token: _self.Util.getjwtToken(),
        },
        data: {
          jobId: jobId,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }).then(function (e) {
        var resp = e.data;
        // console.log('求职人', resp);
        if (resp.success) {
          _self.memjoblist = resp.data.list;
          _self.connt = resp.data.pager.recordCount;
        } else {
          _self.memjoblist = [];
          _self.connt = null;
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    findData: function () {
      var _self = this;
      axios({
        url: _self.API.ajob.detail,
        headers: {
          token: _self.Util.getjwtToken(),
        },
        params: {
          id: _self.jobid,
        }
      }).then(function (e) {
        var resp = e.data;
        if (resp.success) {
          //学历
          resp.data.jobinfo.educationShow = _self.Util.eduShow(resp.data.jobinfo.educationRequire);
          //亮点
          if (resp.data.jobinfo.jobLightPoint) {
            resp.data.jobinfo.jobLightPointArr = resp.data.jobinfo.jobLightPoint.split('-');
          } else {
            resp.data.jobinfo.jobLightPointArr = [];
          }
          _self.JobDetail = resp.data;
          //地图
          _self.setMap();
          // console.log('JobDetail',  _self.JobDetail);
        }
      })
    },
    setMap: function () {
      var _self = this;
      if (_self.JobDetail.enterprise.entLng > 0
        && _self.JobDetail.enterprise.entLat > 0) {
        lazyAMapApiLoaderInstance.load().then(() => {
          var point = new AMap.LngLat(_self.JobDetail.enterprise.entLng, _self.JobDetail.enterprise.entLat);
          _self.map = new AMap.Map('mapBox', {
            center: point,
            zoom: 13
          });
        });
      }
    },
    //编辑职位信息
    editorJob: function () {
      var _self = this;
      this.$parent.pullItem("编辑职位信息", "JobAdd", { jobid: _self.jobid })
    },
    //查看详情
    getResDetail: function (row) {
      this.$parent.pullItem("简历详情", "ResumeDetail", { id: row.memid })
    },
    handleSizeChange: function (e) {
      this.pageSize = e;
      this.getMemjobList();
    },
    handleCurrentChange: function (e) {
      this.params = e;
      this.getMemjobList();
    }
  }

}
</script>

<style scoped lang="scss">
.home {
  background: #f6f7f6;
}
.ent_main {
  height: 100%;
  background-color: #fff;
}
.ent_main .tilte_btn {
  background-color: #ffffff;
}
.isDivcom {
  color: #000;
  border-bottom: 2px solid #000000;
}
.audit-content {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  align-items: center;

  .main-info {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: center;
    .left-content {
      width: 24%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }
    .mid-content {
      width: 38%;
    }
    .right-content {
      width: 38%;
    }

    .line-info {
      width: 100%;
      height: 30px;
      display: flex;
      flex-flow: row nowrap;

      .info-label {
        width: 25%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
      }
      .info-value {
        width: 75%;
        padding-left: 10px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
      }
    }
  }
  .main-hobby {
    width: 100%;
    min-height: 50px;
    display: flex;
    flex-flow: row wrap;
    padding: 5px;
  }

  .main-audit {
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    .audit-label {
      width: 10%;
    }
    .audit-c {
      width: 70%;
    }
  }
}
</style>